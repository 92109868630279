<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Reports" icon="reports-o.png"
          :para="'Please select the options below to generate your report.'"></page-heading>

        <success-panel
          :content="'You can adjust the settings to alter the amount / type of data to include. Once done, click on export.'"></success-panel>

        <div class="reports-search">
          <div class="reports-search-row">
            <div class="reports-search-label" id="lblReportType">Report Type</div>
            <select v-model="type" aria-labelledby="lblReportType" class="reports-type-select">
              <option value="order" v-if="ordersAvailabe">Orders</option>
              <option value="shortage">Discrepancies</option>
              <option value="package">Package Report</option>
              <option value="package-search">Package Search</option>
              <option value="processed-bags">Cash Processing</option>
            </select>
          </div>
          <template v-if="type != 'package-search' && user_type == 'hq-user'">
            <div class="reports-search-row reduced-margin">
              <div class="reports-search-label desktop">&nbsp;</div>
              <input id="all-sites" v-model="all_sites" style="margin-bottom: 3rem;" class="reports-search-checkbox"
                type="checkbox" />
              <label for="all-sites">All sites</label>
            </div>

            <div class="reports-search-row pad-top" v-if="!all_sites">
              <div id="lblSelectSites" class="reports-search-label">Select Site/s:</div>
              <input aria-labelledby="lblSelectSites" type="text" class="reports-search-input"
                v-model.trim="site_search" placeholder="Search by site number or name" @input="page_number = 1" />
            </div>

            <div class="all-sites">
              <template v-if="!all_sites">
                <table class="reports-accounts">
                  <thead>
                    <tr>
                      <td>Site Number</td>
                      <td>Site Name</td>
                      <td>Customer Ref</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in pagedAccounts" :key="account.account_number">
                      <td>{{ account.formattedAccountNumber }}</td>
                      <td>{{ account.company }}</td>
                      <td>{{ account.customer_ref }}</td>

                      <td>
                        <input type="checkbox" v-model="account_numbers" :value="account.account_number" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="reports-accounts-nav">
                  <a href="" v-if="page_number > 1" class="prev" @click.prevent="page_number--">&lt; Prev</a>
                  <a href="" v-if="page_number < numberOfPages" class="next" @click.prevent="page_number++">Next
                    &gt;</a>
                </div>
              </template>
            </div>
          </template>
        </div>

        <div class="reports-search lower">
          <template v-if="type !== 'package-search'">
            <div class="reports-search-row">
              <div class="reports-search-label break-mobile">Select Dates:</div>
              <flat-pickr ref="fromDateWrapper" v-model="from_date" :config="date_config" @input="resetFilter"
                class="reports-search-date" placeholder="From..."></flat-pickr>
              <flat-pickr ref="toDateWrapper" v-model="to_date" :config="date_config" @input="resetFilter"
                class="reports-search-date" placeholder="To..."></flat-pickr>
            </div>
            <div class="reports-search-row filters">
              <div class="reports-search-label break-mobile">QUICK FILTERS:</div>
              <a href :class="{ sel: quick_filter == 'past_30_days' }" @click.prevent="quickFilter('past_30_days')">Past
                30
                days</a>
              <a href :class="{ sel: quick_filter == 'last_month' }" @click.prevent="quickFilter('last_month')">Last
                month</a>
              <a v-if="oldest[type] < last_month_from" href :class="{ sel: quick_filter == 'last_3_months' }"
                @click.prevent="quickFilter('last_3_months')">Last 3 months</a>
              <a v-if="oldest[type] < last_3_months_from" href :class="{ sel: quick_filter == 'last_6_months' }"
                @click.prevent="quickFilter('last_6_months')">Last 6 months</a>
              <a v-if="oldest[type] < last_6_months_from" href :class="{ sel: quick_filter == 'past_year' }"
                @click.prevent="quickFilter('past_year')">Past year</a>
            </div>
          </template>
          <template v-if="type == 'package-search'">
            <div class="reports-search-row">
              <div class="reports-search-label break-mobile" aria-label="lblPackageNumber">Pack Number:</div>
              <input aria-labelledby="lblPackageNumber" type="text" class="txt pack-number"
                v-model.trim="pack_number" />
            </div>
          </template>
        </div>

        <div class="actions">
          <button class="negative" @click="resetSelection">
            Reset <font-awesome-icon icon="undo" aria-hidden="true" role="presentation" />
          </button>
          <button :disabled="exporting" @click="exportOrSearch">
            <template v-if="type == 'package-search'">
              <template v-if="!exporting">Search <font-awesome-icon icon="search" aria-hidden="true"
                  role="presentation" />
              </template>
              <template v-if="exporting">Searching...</template>
            </template>
            <template v-else>
              <template v-if="!exporting">Export <font-awesome-icon icon="file-download" aria-hidden="true"
                  role="presentation" />
              </template>
              <template v-if="exporting">Exporting...</template>
            </template>
          </button>

          <error-panel v-if="error_message" :content="error_message"></error-panel>

          <div class="all-sites" v-if="packages.length">
            <template>
              <table class="reports-accounts">
                <thead>
                  <tr>
                    <td>Site Name</td>
                    <td>Pack Number</td>
                    <td>Expected Value</td>
                    <td>Branch Name</td>
                    <td>Service Original Date</td>
                    <td v-if="packages[0].Service_Type_Name == 'Collection'">Collection Date</td>
                    <td>Destination</td>
                    <td>Delivery Date</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="packageData in packages" :key="packageData.Pack_Number_Id">
                    <td>{{ packageData.Site_name }}</td>
                    <td>{{ packageData.Pack_Number_Id }}</td>
                    <td>{{ packageData.Package_value }}</td>
                    <td>{{ packageData.Servicing_Depot_Name }}</td>
                    <td>{{ packageData.Service_Date.split("T")[0].split("-").reverse().join("/") }}</td>
                    <td v-if="packageData.Service_Type_Name == 'Collection'">{{ packageData.Collection_date }}</td>
                    <td>{{ packageData.To_Location_Name }}</td>
                    <td>{{ packageData.Delivery_date }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>

          <button :disabled="exporting" @click="exportPackageSearch" v-if="packages.length">
            <template>
              <template v-if="!exportingPackageSearch">Export <font-awesome-icon icon="file-download" aria-hidden="true"
                  role="presentation" />
              </template>
              <template v-if="exportingPackageSearch">Exporting...</template>
            </template>
          </button>
        </div>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import dateUtil from "date-and-time"
import api from "@/services/api"
import { formatAccountNumber } from "@/util/vars"

export default {
  data() {
    var today = new Date()
    var past30 = new Date()
    past30.setDate(past30.getDate() - 29)

    var month = today.getMonth()
    var year = today.getFullYear()
    var lastmonthto = new Date(year, month, 0)

    month -= 1
    var lastmonthfrom = new Date(year, month, 1)

    month = today.getMonth()
    year = today.getFullYear()
    var last3monthto = new Date(year, month, 0)

    month -= 3
    var last3monthfrom = new Date(year, month, 1)

    month = today.getMonth()
    year = today.getFullYear()
    var last6monthto = new Date(year, month, 0)

    month -= 6
    var last6monthfrom = new Date(year, month, 1)

    var pastyear = new Date()
    pastyear.setDate(pastyear.getDate() - 365)

    return {
      pastyear,
      today,

      all_sites: true,
      type: "order",
      display: "Days",
      quick_filter: "past_30_days",
      latestRequest: 0,
      reportData: null,
      accounts: [],
      oldest: {},

      past_30_days_from: dateUtil.format(past30, "YYYY-MM-DD"),
      past_30_days_to: dateUtil.format(today, "YYYY-MM-DD"),
      last_month_from: dateUtil.format(lastmonthfrom, "YYYY-MM-DD"),
      last_month_to: dateUtil.format(lastmonthto, "YYYY-MM-DD"),
      last_3_months_from: dateUtil.format(last3monthfrom, "YYYY-MM-DD"),
      last_3_months_to: dateUtil.format(last3monthto, "YYYY-MM-DD"),
      last_6_months_from: dateUtil.format(last6monthfrom, "YYYY-MM-DD"),
      last_6_months_to: dateUtil.format(last6monthto, "YYYY-MM-DD"),
      past_year_from: dateUtil.format(pastyear, "YYYY-MM-DD"),
      past_year_to: dateUtil.format(today, "YYYY-MM-DD"),

      from_date: dateUtil.format(past30, "YYYY-MM-DD"),
      to_date: dateUtil.format(today, "YYYY-MM-DD"),

      site_search: "",
      pack_number: "",
      account_numbers: [],
      page_size: 5,
      page_number: 1,
      packages: [],

      error_message: "",
      exporting: false,
      exportingPackageSearch: false
    }
  },
  created() {
    if (this.user_type == "hq-user")
      api.request("get", "account?all=true").then(res => {
        res.forEach(a => (a.formattedAccountNumber = this.formatAccNo(a.account_number)))

        this.accounts = res

        if (!this.ordersAvailabe) this.type = "shortage"
      })

    api.request("get", "order?type=oldest").then(res => {
      this.oldest.order = res
    })

    api.request("get", "shortage?type=oldest").then(res => {
      this.oldest.shortage = res
    })

    this.oldest.package = dateUtil.format(new Date(new Date().setDate(new Date().getDate() - 90)), "YYYY-MM-DD")
  },
  mounted() {
    this.$nextTick().then(function () {
      if (window.innerWidth < 600) {
        document.getElementById("reportsholder").style.width = window.innerWidth - 40 + "px"
      }
    })

    const fromDateWrapperElement = this.$refs.fromDateWrapper.getElem().nextElementSibling
    fromDateWrapperElement.setAttribute("aria-label", "From...")

    const toDateWrapperElement = this.$refs.toDateWrapper.getElem().nextElementSibling
    toDateWrapperElement.setAttribute("aria-label", "To...")
  },
  watch: {
    type: function (newType) {
      this.packages = []
      if (newType == 'package' && this.to_date == dateUtil.format(this.today, "YYYY-MM-DD")) {
        this.to_date = dateUtil.format(new Date(new Date().setDate(new Date().getDate() - 1)), "YYYY-MM-DD")
      }
    }
  },
  computed: {
    ordersAvailabe() {
      return !!this.accounts.filter(account => account.credit_limit > 0).length
    },
    date_config: function () {
      let maxDate = new Date()
      if (this.type == 'package') maxDate.setDate(maxDate.getDate() - 1)

      return {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"]
          }
        },
        minDate: this.oldest[this.type],
        maxDate: dateUtil.format(maxDate, "YYYY-MM-DD"),
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d"
      }
    },
    user_type: function () {
      return this.$store.state.user_type
    },
    numberOfPages: function () {
      return Math.ceil(this.filteredAccounts.length / this.page_size)
    },
    pagedAccounts() {
      return this.filteredAccounts.slice((this.page_number - 1) * this.page_size, this.page_number * this.page_size)
    },
    filteredAccounts() {
      if (!this.site_search) return this.accounts

      var site_search = this.site_search.toLowerCase()

      return this.accounts.filter(
        a =>
          (a.account_number && a.account_number.includes(site_search)) ||
          (a.formattedAccountNumber && a.formattedAccountNumber.toLowerCase().includes(site_search)) ||
          (a.company && a.company.toLowerCase().includes(site_search)) ||
          (a.customer_ref && a.customer_ref.toLowerCase().includes(site_search))
      )
    }
  },
  methods: {
    formatAccNo(acc_no) {
      return formatAccountNumber(acc_no)
    },
    exportOrSearch() {
      this.exporting = true
      this.error_message = ""
      this.packages = []

      api
        .request(
          "get",
          this.type +
          "?type=report&export=true&display=" +
          this.display +
          "&from_date=" +
          this.from_date +
          "&to_date=" +
          this.to_date +
          (this.account_numbers.length ? "&account_numbers=" + JSON.stringify(this.account_numbers) : "") +
          "&pack_number=" +
          this.pack_number + "&all_sites=" +
          this.all_sites, undefined, undefined, this.type == "processed-bags" ? "blob" : undefined
        )
        .then(data => {
          if (this.type == "package-search") {
            this.packages = [data]
          } else if (this.type == "processed-bags") {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("download", "processed-bags.xlsx");
            link.setAttribute("href", url);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          } else {
            var encodedUri = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(data)

            var link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", "Export.csv")
            document.body.appendChild(link)

            link.click()
          }
        }).catch(err => {
          this.error_message = err.response.data.message.Error_Message
        }).finally(() => {
          this.exporting = false
        })
    },
    exportPackageSearch() {
      this.exportingPackageSearch = true
      this.error_message = ""

      api
        .request(
          "get",
          this.type +
          "?type=export&export=true&display=" +
          this.display +
          "&from_date=" +
          this.from_date +
          "&to_date=" +
          this.to_date +
          (this.account_numbers.length ? "&account_numbers=" + JSON.stringify(this.account_numbers) : "") +
          "&pack_number=" +
          this.pack_number + "&all_sites=" +
          this.all_sites, undefined, undefined, this.type == "package-search" ? "blob" : undefined
        )
        .then(data => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", this.pack_number + ".xlsx");
          link.setAttribute("href", url);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }).catch(err => {
          const blob = new Blob([err.response.data], { type: 'application/octet-stream' });

          const reader = new FileReader();

          reader.onload = (event) => {
            const text = event.target.result;
            this.error_message = text
          };

          reader.readAsText(blob);
        }).finally(() => {
          this.exportingPackageSearch = false
        })
    },
    resetSelection() {
      this.all_sites = true
      this.account_numbers = []
      this.display = "Days"
      this.quickFilter("past_30_days")
      this.resetFilter()
    },
    resetFilter() {
      if (this.from_date > this.to_date) {
        var temp = this.from_date
        this.from_date = this.to_date
        this.to_date = temp

        return
      }

      var filters = ["past_30_days", "last_month", "last_3_months", "last_6_months", "past_year"]

      this.quick_filter = "custom"
      filters.forEach(val => {
        if (this.from_date == this[val + "_from"] && this.to_date == this[val + "_to"]) this.quick_filter = val
      })

      if (this.quick_filter == "past_30_days") this.display = "Days"
      if (this.quick_filter == "last_month") this.display = "Days"
      if (this.quick_filter == "last_3_months") this.display = "Weeks"
      if (this.quick_filter == "last_6_months") this.display = "Months"
      if (this.quick_filter == "past_year") this.display = "Months"
    },
    quickFilter(val) {
      this.quick_filter = val

      this.from_date = this[val + "_from"]
      this.to_date = this[val + "_to"]
    },
    removeAccount(val) {
      this.account_numbers = this.account_numbers.filter(item => item !== val)
    }
  }
}
</script>

<style scoped>
.reports-search-label {
  font-size: 2rem;
  display: inline-block;
  width: 15rem;
  margin-right: 2rem;
  font-weight: bold;
}

.filters {
  color: #dc002e;
}

.filters a {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.filters a.sel {
  border-bottom: 1px solid #dc002e;
}

.filters .reports-search-label {
  font-size: 1.7rem;
}

.reports-search-row label,
.reports-search-row input,
.reports-search-select {
  cursor: pointer;
}

.reports-search-checkbox {
  margin-right: 1.5rem;
  transform: scale(1.3);
}

.reduced-margin {
  margin-top: -1rem;
}

.reports-accounts {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.reports-accounts td {
  padding: 1rem 2rem;
  border-top: 1px solid #eee;
}

.reports-accounts thead td {
  background: #eee;
  font-weight: bold;
}

.reports-accounts tr:nth-child(even) td {
  background: #f8f8f8;
}

.reports-accounts input {
  cursor: pointer;
  transform: scale(1.3);
}

.reports-accounts-nav {
  margin-bottom: 2rem;
}

.reports-accounts-nav a {
  background: #dc002e;
  display: inline-block;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #fff;
}

.reports-accounts-nav a:hover {
  background: #c4002a;
}

.reports-accounts-selected {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.reports-accounts-selected a {
  background: #eee;
  display: inline-block;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: #333;
}

.reports-accounts-selected a:hover {
  background: #dc002e;
  color: white;
}

.reports-accounts-selected a span {
  font-weight: bold;
  margin-right: 0.5rem;
}

.reports-display {
  text-align: center;
  margin-bottom: 2rem;
}

.reports-display span {
  font-weight: bold;
}

.reports-display select {
  border: 0;
  border-bottom: 1px solid #dc002e;
  color: #dc002e;
  cursor: pointer;
  border-radius: 0;
  margin-left: 1rem;
}

.reports-display select:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.reports-search-checkbox:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.pack-number {
  width: 100%;
  max-width: 40rem;
}


@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .break-mobile {
    display: block;
    margin-bottom: 1rem;
  }
}
</style>
